var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mytb" } },
    [
      _vm._l(_vm.salaryList, function (item, index) {
        return _c("div", { key: item.id, staticClass: "salary-plan-table" }, [
          _c("div", { staticClass: "table-head flex-just-between" }, [
            _c(
              "span",
              { staticClass: "flex-align-center" },
              [
                _c("svg", { staticClass: "iconpark-icon dragIcon" }, [
                  _c("use", { attrs: { href: "#drag" } }),
                ]),
                _c("div", { staticClass: "table-text" }, [
                  _vm._v(" " + _vm._s(item.typeName) + " "),
                ]),
                _c("a-switch", {
                  attrs: { size: "small" },
                  on: {
                    change: function ($event) {
                      return _vm.onChange(item)
                    },
                  },
                  model: {
                    value: item.enabled,
                    callback: function ($$v) {
                      _vm.$set(item, "enabled", $$v)
                    },
                    expression: "item.enabled",
                  },
                }),
              ],
              1
            ),
            item.typeNo && item.typeNo.slice(0, 1) === "B"
              ? _c("span", { staticClass: "operation-icon" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "iconpark-icon icon",
                      on: {
                        click: function ($event) {
                          return _vm.editClass(
                            Object.assign({}, item, { type: "edit" })
                          )
                        },
                      },
                    },
                    [_c("use", { attrs: { href: "#bianji" } })]
                  ),
                  _c(
                    "svg",
                    {
                      staticClass: "iconpark-icon icon",
                      on: {
                        click: function ($event) {
                          return _vm.deleteHandle(item.typeNo, "class")
                        },
                      },
                    },
                    [_c("use", { attrs: { href: "#delete-one" } })]
                  ),
                ])
              : _vm._e(),
          ]),
          item.enabled
            ? _c(
                "div",
                [
                  _c("custom-table", {
                    attrs: {
                      columns: _vm.columns,
                      "data-source": _vm.salaryList[index].itemList || [],
                      pagination: false,
                    },
                    on: { change: _vm.loadDataList },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "calcType",
                          fn: function (ref) {
                            var scope = ref.scope
                            return _c("span", { staticClass: "calcType" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.record.calcType === "1" ? "+" : "-"
                                  ) +
                                  " "
                              ),
                            ])
                          },
                        },
                        {
                          key: "operation",
                          fn: function (ref) {
                            var scope = ref.scope
                            return [
                              !["A06", "A07"].includes(item.typeNo)
                                ? _c(
                                    "span",
                                    { staticClass: "operation-btn-box" },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { type: "link" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editSalary(
                                                scope.record
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 编辑 ")]
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          staticClass: "delete",
                                          attrs: { type: "link", danger: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteHandle(
                                                scope.record.itemNo,
                                                item.typeNo === "A01"
                                                  ? "fixed"
                                                  : "item"
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 删除 ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  !["A01", "A07"].includes(item.typeNo)
                    ? _c(
                        "div",
                        { staticClass: "add-salary" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { prefix: "piliangdaoru" },
                              on: {
                                click: function ($event) {
                                  return _vm.addSalary(item)
                                },
                              },
                            },
                            [_vm._v(" 添加薪资项 ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      _c("AddOrEditCategoryChild", {
        ref: "handleChild",
        attrs: { "salary-id": _vm.salaryId },
        on: { refreshData: _vm.initData },
      }),
      _c("NewCategory", { ref: "newCategory", on: { refresh: _vm.initData } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }